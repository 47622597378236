@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-header {
    width: 100vw;
    height: 100vh;
    position: relative;
    text-align: center;
    background-color: $color-grey-100;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
}

.c-header__inner {
    // text-align: center;
    // max-width: 515px;
    // margin: 0 auto 0;
    // position: relative;
    // top: 172px;
    text-align: center;
    max-width: 515px;
    margin: 0 auto 0;
    position: relative;
    top: -60px;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 839px) {
        padding: 0 21px;
    }
}

.c-header__splash {
    opacity: 1;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top:0;
    z-index: 90;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    object-fit: cover;

    &-inner {
        width: 100vw;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        object-fit: cover;
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.c-header__logo {
    margin-top: 90px;
    margin-bottom: -90px;
    position: relative;
    width: 200px;
    height: 200px;
    z-index: 91;

    @media (max-width: 839px) {
        width: 160px;
        height: 160px;
    }

    .c-logo {
        display: inline-block;
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        vertical-align: middle;
        overflow: hidden;

        .svg-content {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.c-header__title {
    @include font-set(24, 36, 2.5, 500, $font-graphik);
    width: 100%;
    margin-top: 46px;
    margin-bottom: 54px;
    padding: 0;
    color: $color-white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    text-align: center;
    opacity: 0;
    position: relative;
    z-index: 91;

    @media (max-width: 839px) {
        @include font-set(14, 22, 1.46, 500);
        margin-top: 36px;
        margin-bottom: 24px;
    }
}

.c-header__title span {
    border-bottom: 2px solid $color-white;
}

.c-header__download {
    background: $color-blue-100;
    border-radius: 9999px;
    padding: 21px 49px;
    color: $color-white;
    font-size: 14px;
    letter-spacing: 2.2px;
    line-height: 15px;
    font-weight: 500;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    text-decoration: none;
}

.c-menu-toggle {
    display: none;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 30px;
    height: 31px;
    position: absolute;
    right: 40px;
    top: 35px;
    transition: all 0.2s cubic-bezier(0.785,0.135,0.15,0.86);
    opacity: 1;
    z-index: 101;

    @media (max-width: 839px) {
        display: block;
    }

    div {
        background: $color-white;
        transition: all 0.2s cubic-bezier(0.785,0.135,0.15,0.86);
        position: absolute;
        top: 10px;
        height: 2px;
        width: 100%;
    }

    div + div {
        top: 18px;
    }

    span {
        @include font-set(10, 11, 0.83, 600);
        text-transform: uppercase;
        color: $color-white;
        position: relative;
        top: 18px;
    }

    .s-nav-active & {
        @media (max-width: 839px) {
            display: block;
            position: fixed;
        }

        div {
            top: 14px;
            transform: rotate(45deg);
        }

        div + div {
            top: 14px;
            transform: rotate(-45deg);
        }
    }
}
