@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-sponsors {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 60px;
    padding: 0 20px;

    @media (max-width: 839px) {
        margin-bottom: 30px;
    }

    &--hide-desktop {
        display: none;

        @media (max-width: 839px) {
            display: block;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        @include font-set(20, 26, 0, 600);
        color: $color-blue-100;
        margin-bottom: 40px;

        @media (max-width: 839px) {
            margin-bottom: 17px;
        }
    }

    &__title--right {
        @media (max-width: 839px) {
            display: none;
        }
    }

    &__logos {
        // display: grid;
        // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        // grid-column-gap: 24px;
        // grid-row-gap: 24px;

        // @media (max-width: 839px) {
        //     grid-template-columns: 1fr 1fr 1fr;
        //     grid-column-gap: 10px;
        //     grid-row-gap: 10px;
        // }
        display: flex;
        flex-flow: row wrap;

        h4 {
            margin: 0 12px;
        }

        & > .c-sponsor {
            //border: 1px solid $color-grey-100;

            @media (min-width: 840px) {
                width: calc(16.66% - (120px / 6));
                margin-right: 24px;

                &:nth-child(6n) {
                    margin-right: 0;
                }

                &:nth-child(n + 7) {
                    margin-top: 24px;
                }
            }

            @media (max-width: 839px) {
                width: calc(33.33% - (28px / 3));
                margin-right: 14px;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:nth-child(n + 4) {
                    margin-top: 14px;
                }
            }

            &--big {
                width: 100%;
                max-width: 384px;

                &:last-child {
                    margin-right: 0;
                }
            }

            &--hide-mobile {
                display: block;

                @media (max-width: 839px) {
                    display: none;
                }
            }
        }
    }
}

.c-sponsor {
    background: white;
    position: relative;
    padding: 20px 20px 20px 20px;

    &:before {
        content: ' ';
        display: block;
        width: 100%;
        padding-top: 100%
    }

    & > div {
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        object-fit: scale-down;

        figure {
            width: 100%;
            height: 100%;
        }

        img {
            object-fit: contain;
        }

        .c-image__background {
            background: white;
        }
    }

    &--big {
        grid-column: span 2;

        @media (max-width: 839px) {
            grid-column: span 3;
        }

        &:before {
            padding-top: 60%;
        }
    }
}
