// Mixins
// FONTS

/* stylelint-disable */
@mixin define-font(
    $font-name,
    $font,
    $weight: normal,
    $style: normal,
    $woff2: false
) {
    @if ($woff2) {
        @font-face {
            font-family: $font-name;
            font-weight: $weight;
            font-style: $style;
            src: url(font-path("#{$font}.eot")); // IE9 Compat Modes
            src: url(font-path("#{$font}.eot?#iefix"))
                    format("embedded-opentype"),
                url(font-path("#{$font}.svg##{$font-name}")) format("svg"),
                url(font-path("#{$font}.woff2")) format("woff2"),
                url(font-path("#{$font}.woff")) format("woff"),
                url(font-path("#{$font}.ttf")) format("truetype"); // Safari, Android, iOS
        }
    } @else {
        @font-face {
            font-family: $font-name;
            font-weight: $weight;
            font-style: $style;
            src: url(font-path("#{$font}.eot")); // IE9 Compat Modes
            src: url(font-path("#{$font}.eot?#iefix"))
                    format("embedded-opentype"),
                url(font-path("#{$font}.svg##{$font-name}")) format("svg"),
                url(font-path("#{$font}.woff")) format("woff"),
                url(font-path("#{$font}.ttf")) format("truetype"); // Safari, Android, iOS
        }
    }
}

@mixin font-set(
    $fontsize,
    $lineheight: false,
    $letterspacing: false,
    $fontweight: false,
    $fontfam: false,
    $fontstyle: false
) {
    font-size: $fontsize * 1px;

    @if $lineheight {
        line-height: $lineheight * 1px;
    }

    @if $letterspacing {
        letter-spacing: $letterspacing * 1px;
    }

    @if $fontweight {
        font-weight: $fontweight;
    }

    @if $fontfam {
        font-family: $fontfam;
    }

    @if $fontstyle {
        font-style: $fontstyle;
    }
}
/* stylelint-enable */
