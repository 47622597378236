.highlight-green,
html .group-highlight--green {
  .highlight,
  p a {
    color: $highlight-green;
  }

  .highlight-bg,
  &.highlight-bg,
  .c-arrow--highlight {
    background-color: $highlight-green;

    &__light {
      background-color: $color-green-50;
    }
  }
  .c-navigation__link {
    &.s-current,
    &:hover,
    &:focus,
    &--active {
      color: $highlight-green;

      &::before {
        background-color: $highlight-green;
      }
    }
  }

  .c-download {
    background-color: rgba($color-green-50, .13);

    .c-section--light & {
      background-color: rgba($color-green-50, 1);
    }
  }
}

.highlight-blue,
html .group-highlight--blue {
  .highlight,
  p a {
    color: $highlight-blue;
  }
  .highlight-bg,
  &.highlight-bg,
  .c-arrow--highlight {
    background-color: $highlight-blue;

    &__light {
      background-color: $color-blue-100;
    }
  }
  .c-navigation__link {
    &.s-current,
    &:hover,
    &:focus,
    &--active {
      color: $color-blue-100;

      &::before {
        background-color: $color-blue-100;
      }
    }
  }

  .c-download {
    background-color: rgba($color-blue-100, .13);

    .c-section--light & {
      background-color: rgba($color-blue-100, 1);
    }
  }
}

.highlight-red,
html .group-highlight--red {
  .highlight,
  p a {
    color: $highlight-red;
  }
  .highlight-bg,
  &.highlight-bg,
  .c-arrow--highlight {
    background-color: $highlight-red;

    &__light {
      background-color: $color-red-50;
    }
  }
  .c-navigation__link {
    &.s-current,
    &:hover,
    &:focus,
    &--active {
      color: $highlight-red;

      &::before {
        background-color: $highlight-red;
      }
    }
  }

  .c-download {
    background-color: rgba($color-red-50, .13);

  }
  .c-section--light {
    .c-download {
      background-color: rgba($highlight-red, .13);
    }
    background-color: rgba($color-red-50, 1);
  }

  .c-section--white {
    .c-download {
      background-color: rgba($highlight-red, .13);
    }
    background-color: $color-white;
  }
}

.highlight-gold,
html .group-highlight--gold {
  .highlight,
  p a {
    color: $highlight-gold;
  }
  .highlight-bg,
  &.highlight-bg,
  .c-arrow--highlight {
    background-color: $highlight-gold;

    &__light {
      background-color: $color-gold-50;
    }
  }
  .c-navigation__link {
    &.s-current,
    &:hover,
    &:focus,
    &--active {
      color: $highlight-gold;

      &::before {
        background-color: $highlight-gold;
      }
    }
  }

  .c-download {
    background-color: rgba($color-gold-50, .13);

  }
  .c-section--light {
    .c-download {
      background-color: rgba($highlight-gold, .13);
    }
    background-color: rgba($color-gold-50, 1);
  }

  .c-section--white {
    .c-download {
      background-color: rgba($highlight-gold, .13);
    }
    background-color: $color-white;
  }
}

