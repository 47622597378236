* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

html {
    // scroll-behavior: smooth;
}

body {
    font-size: 1em;
    font-family: $font-graphik;
    font-weight: 400;
    margin: 0;
    font-synthesis: none;
    font-feature-settings: 'lnum';
    color: $color-white;
    background-color: $color-dark;
    overflow-x: hidden;

    /* stylelint-disable */
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    /* stylelint-enable */

    hr {
        display: block;
        width: calc(100% - 40px);
        max-width: 1200px;
        border: none;
        height: 1px;
        background-color: rgba($color-dark, 0.1);
        margin: 0 auto 90px;

        @media (max-width: 839px) {
            margin-bottom: 45px;
        }

        &.c-hr--light {
            background-color: rgba($color-white, 0.3);
        }
    }

    &.s-nav-active {
        @media (max-width: 839px) {
            position: fixed;
            overflow: hidden;
        }
    }

    &.s-splash-active {
        height: 100vh;
        overflow-y: hidden;
        padding-right: 15px;
        position: fixed;
    }
}
