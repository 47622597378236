//Code Pro
//-----------------------------

//Black
@font-face {
    font-family: 'Code Pro';
    src: local('Code Pro Black'), local('Code-Pro-Black'),
        url('../fonts/Code-Pro-Black/Code-Pro-Black.woff2') format('woff2'),
        url('../fonts/Code-Pro-Black/Code-Pro-Black.woff') format('woff'),
        url('../fonts/Code-Pro-Black/Code-Pro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}


//Graphik
//-----------------------------

//Regular
@font-face {
    font-family: 'Graphik';
    src: local('Graphik Regular'), local('Graphik-Regular'),
        url('../fonts/Graphik-Regular/Graphik-Regular.woff2') format('woff2'),
        url('../fonts/Graphik-Regular/Graphik-Regular.woff') format('woff'),
        url('../fonts/Graphik-Regular/Graphik-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

//Medium
@font-face {
    font-family: 'Graphik';
    src: local('Graphik Medium'), local('Graphik-Medium'),
        url('../fonts/Graphik-Medium/Graphik-Medium.woff2') format('woff2'),
        url('../fonts/Graphik-Medium/Graphik-Medium.woff') format('woff'),
        url('../fonts/Graphik-Medium/Graphik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

//Bold
@font-face {
    font-family: 'Graphik';
    src: local('Graphik Bold'), local('Graphik-Bold'),
        url('../fonts/Graphik-Bold/Graphik-Bold.woff2') format('woff2'),
        url('../fonts/Graphik-Bold/Graphik-Bold.woff') format('woff'),
        url('../fonts/Graphik-Bold/Graphik-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
