@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-feature {
    $this: &;

    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    overflow-x: hidden;

    &__header {
        margin-bottom: 20px;

        & > :last-child {
            margin-bottom: 0;
        }
    }

    &__number {
        @include font-set(150, 171, 0, 600);
        margin: 0 0 6px;
    }

    &__title {
        font-family: $font-code;
        color: $color-blue-100;
        margin: 0 0 25px;
    }

    &__text {
        h3 {
            @include font-set(20, 26, 0, 500);
        }
    }

    &__content {
        max-width: 870px;
        text-align: left;
        margin-top: -312px;
        background-color: $color-dark;
        padding: 60px 50px;
        z-index: 2;

        @media (max-width: 839px) {
            margin-top: -137px;
            max-width: calc(100% - 50px);
            padding: 30px;
        }

        @media (max-width: 479px) {
            margin-top: -90px;
            max-width: calc(100% - 30px);
        }
    }

    &__image {
        width: 894px;
        //height: 504px;
        height: auto;
        background-color: $color-grey-300;
        flex-shrink: 0;
        margin-left: auto;

        @media (max-width: 839px) {
            max-width: calc(100% - 50px);
        }
        @media (max-width: 479px) {
            max-width: calc(100% - 30px);
        }
    }

    &--even {
        align-items: flex-end;

        #{$this}__image {
            margin-left: 0;
            margin-right: auto;
        }

        #{$this}__content {
            margin-left: auto;
        }
    }

    & + & {
        margin-top: 90px;
    }
}
