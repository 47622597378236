@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-values {
    $this: &;

    display: flex;
    flex-flow: row wrap;
    height: 720px;

    @media (max-width: 839px) {
        height: 256px;
    }

    &__title {
        margin-bottom: 75px;
        font-size: 26px;
        letter-spacing: 0.19;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
    }

    &__pattern {
        color: $color-white;
        width: 772px;
        position: absolute;
        opacity: 0.2;

        svg {
            fill: currentColor;
        }

        @media (max-width: 839px) {
            width: 256px;
        }
    }

    &__card {
        position: relative;
        width: 50%;
        height: 100%;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        color: $color-white;
        overflow: hidden;

        &--left {
            align-items: flex-end;
            text-align: right;
            color: $color-white;
            background-color: rgba($color-blue-100, 1);
            padding-right: 50px;

            @media (max-width: 839px) {
                padding-right: 12px;
            }

            #{$this}__pattern {
                top: 120px;
                right: 50px;
                transform: rotateZ(180deg);

                @media (max-width: 839px) {
                    top: 61px;
                    right: 0;
                }
            }

        }

        &--right {
            align-items: flex-start;
            text-align: left;
            padding-left: 50px;

            @media (max-width: 839px) {
                padding-left: 12px;
            }

            #{$this}__pattern {
                bottom: 116px;

                @media (max-width: 839px) {
                    bottom: 44px;
                    left: 0;
                }
            }
        }

    }

    &__list {
        list-style: none;
        margin: 10px 0 0;
        padding: 0;

        h2 {
            @include font-set(54, 54, 0, 900, $font-code);
            margin: 0;

            @media (max-width: 1105px) {
                @include font-set(40, 40, 0, 900, $font-code);
            }

            @media (max-width: 839px) {
                @include font-set(21, 21, 0, 900, $font-code);
            }

            @media (max-width: 599px) {
                @include font-set(18, 20, 0, 900, $font-code);
            }
        }
    }
}
