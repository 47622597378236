@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-donuts {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 0 20px;

    @media (max-width: 839px) {
        flex-flow: column wrap;
    }
}

.c-donut {
    $this: &;
    width: 50%;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 90px;

    @media (max-width: 839px) {
        width: 90%;
        padding: 0 15px;
        margin-left: auto;
        margin-right: auto;
    }

    &__graph-container {
        display: flex;
        flex-flow: row;
        width: 100%;

        &--overflow {
            flex-direction: row-reverse;
            justify-content: flex-end;

            @media (max-width: 839px) {
                flex-wrap: wrap;
                padding-left: 0;
            }

            #{$this}__legend {
                @media (max-width: 839px) {
                    width: 100%;
                    flex-shrink: 0;
                }

                &:last-child {
                    padding-left: 50px;
                    width: 380px;
                    flex-shrink: 0;

                    @media (max-width: 839px) {
                        width: auto;
                        padding-left: 0;
                    }
                }
            }
        }

        @media (max-width: 839px) {
            flex-flow: column;
        }
    }

    &__graph {
        width: 380px;
        position: relative;

        @media (max-width: 839px) {
            width: auto;
            display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
    }

    &__label {
        @include font-set(45, 52, 0, 900, $font-code);
        color: $color-white;

        @media (max-width: 839px) {
            @include font-set(28, 34, 0, 900, $font-code);
        }
    }

    &__title {
        @include font-set(14, 14, 2.2, 400);
        text-transform: uppercase;
        width: 100%;
        margin: 0;

        @media (max-width: 839px) {
            //@include font-set(60, 100, 0, 600);
        }
    }

    &__text {
        @include font-set(15, 23, 0, 400);
        width: 100%;
        max-width: 320px;

        @media (max-width: 839px) {
           // @include font-set(60, 100, 0, 600);
        }
    }

    &__legend {
        text-align: left;
        display: flex;
        flex-flow: row wrap;
    }

    &__legend-item {
        @include font-set(13, 18, 0, 400);
        margin-bottom: 21px;
        width: 100%;

        span {
            @include font-set(26, 26, 0.19, 700);
            display: block;
            margin-bottom: 3px;
        }
    }
}
