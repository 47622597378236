@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-medals {
    max-width: 630px;
    margin: 0 auto;
    display: flex;
    column-gap: 60px;
    justify-content: center;

    @media (max-width: 839px) {
        column-gap: 20px;
    }
}

.c-medal {
    $this: &;

    width: 32%;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
    width: 150px;
    height: 150px;
    //background-color: rgba($color-border, 0.4);
    border: 5px solid transparent;
    border-radius: 100%;
    margin-top: 31px;
    top: 0;

    @media (max-width: 839px) {
        width: 100px;
        height: 100px;
        top: 0;
        margin-bottom: 0;
    }

    &__bit {
        position: absolute;
        top: -36px;
        height: 36px;
        width: 101px;
        left: 50%;
        margin-left: -50.5px;

        & > svg {
            fill: currentColor;
        }
    }

    &__number {
        @include font-set(72, 82, 0, 600);
        color: $color-dark;
        text-transform: uppercase;
        margin: 0;

        @media (max-width: 839px) {
            @include font-set(36, 38, 0, 600);
        }
    }

    &__color {
        @include font-set(14, 16, 2.2, 400);
        color: $color-dark;
        text-transform: uppercase;
        margin: 0;

        @media (max-width: 839px) {
            @include font-set(11, 11, 1.9, 600);
        }
    }

    &--bronze {
        background-color: rgba($color-bronze-100, 0.7);
        border-color: $color-bronze-100;

        & > #{$this}__bit {
            color: $color-bronze-100;
        }
    }

    &--silver {
        background-color: rgba($color-silver-100, 0.7);
        border-color: $color-silver-100;

        & > #{$this}__bit {
            color: $color-silver-100;
        }
    }

    &--gold {
        background-color: rgba($color-gold-100, 0.7);
        border-color: $color-gold-100;

        & > #{$this}__bit {
            color: $color-gold-100;
        }
    }
}
