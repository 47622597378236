@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-image--wrapper {
    max-width: 1115px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.c-image {
    position: relative;
    width: auto;
    height: auto;
    margin: 0;

    &__image {
        position: relative;
        z-index: 1;
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;

        // .s-active & {
        //     animation: image-in 0.4s ease-out forwards 0.4s;
        // }

    }

    &__content {
        width: 100%;
        margin-bottom: 75px;
    }

    &__background {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $color-grey-300;

        // .s-active & {
        //     animation: image-in 0.5s ease-out forwards 0.2s;
        // }

    }
}

@keyframes image-in {
    0% {
        clip-path: polygon(0 91%, 100% 84%, 100% 100%, 0 100%);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}
