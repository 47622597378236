@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-report {
    $this: &;

    max-width: 1440px;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    @media (max-width: 839px) {
        padding: 0 12px;
        max-width: 80%;
    }

    @media (max-width: 599px) {
        max-width: 100%;
    }

    &__content {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;
        max-width: calc((486px * 2) + 10%);
        column-gap: 10%;
        margin: 0 auto;

        @media (max-width: 839px) {
            flex-flow: column nowrap;
            align-items: center;
            padding: 0 9px;
            column-gap: 0;
        }
    }

    &__header {
        margin-bottom: 43px;
        text-align: left;
        order: 1;
        flex-basis: 100%;

        .c-report--img-left & {
            margin-left: 55%;
            max-width: 45%;

            @media screen and (max-width: 839px) {
              margin-left: 0;
              max-width: none;
            }

            @media screen and (min-width: 1120px) {
                margin-left: calc(100% - 486px);
            }
        }

        @media (max-width: 839px) {
            order: 2;
            max-width: none;
            margin-bottom: 24px;
            text-align: center;
            width: 100%;
        }

        & > :last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        max-width: 486px;
        .small & {
            font-size: 36px;
        }
        font-family: $font-code;
        margin: 0 0 23px;

        .highlight-green & {
            color: $highlight-green;
        }

        .highlight-red & {
            color: $highlight-red;
        }

        .highlight-blue & {
            color: $highlight-blue;
        }

        .highlight-gold & {
            color: $highlight-gold;
        }

        .group-highlight--green & {
            color: $highlight-green;
        }

        .group-highlight--red & {
            color: $highlight-red;
        }

        .group-highlight--blue & {
            color: $highlight-blue;
        }

        .group-highlight--gold & {
            color: $highlight-gold;
        }

        .highlight-bg & {
            color: $color-dark;
        }

        @media screen and (max-width: 839px) {
          max-width: none;
        }
    }

    &__image {
        height: auto;
        order: 3;
        padding-top: 1.5rem;
        width: 45%;

        .c-image {
            max-width: 483px;
        }

        @media (max-width: 839px) {
            height: 0;
            min-height: 248px;
            width: 100%;
            overflow: hidden;
            padding-top: 100%;
            position: relative;
            margin-left: 0;
            margin-bottom: 45px;
            order: 1;

            & > .c-image {
                max-width: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            & > .c-image > .c-image__image {
                object-position: top center;
            }

        }

        & > :last-child {
            margin: 0;
        }
    }

    &--img-left {

        #{$this}__image {
            order: 2;

            @media (max-width: 839px) {
                order: 2;
            }
        }
    }

    &__text {
        order: 3;
        text-align: left;
        max-width: 486px;
        width: 45%;

        @media screen and (max-width: 839px) {
          width: 100%;
          text-align: center;
        }
        h3 {
            @include font-set(20, 26, 0, 500);
        }
    }

    &__summary {

        &--readmore {
            cursor: pointer;

            .highlight-green & {
                color: $highlight-green;
            }
    
            .highlight-red & {
                color: $highlight-red;
            }
    
            .highlight-blue & {
                color: $highlight-blue;
            }

            .highlight-gold & {
                color: $highlight-gold;
            }
            
            .group-highlight--green & {
                color: $highlight-green;
            }
    
            .group-highlight--red & {
                color: $highlight-red;
            }
    
            .group-highlight--blue & {
                color: $highlight-blue;
            }

            .group-highlight--gold & {
                color: $highlight-gold;
            }
    
            .highlight-bg & {
                color: $color-dark;
            }

        }

        &.hide {
            display: none;

            + .c-report__summary--readmore {
                display: none;
            }
        }
    }



    &__fulltext {
        display: none;

        &.show {
            display: block;
        }
    }

    & + & {
        margin-top: 90px;

        @media (max-width: 839px) {
            margin-top: 45px;
        }
    }
}
