@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';


.c-section {
    $this: &;

    position: relative;
    width: 100%;
    background-color: $color-dark;
    text-align: center;
    color: $color-white;
    padding-top: 120px;
    overflow: hidden;

    @media (max-width: 839px) {
        padding-top: 45px;
    }

    &__head {
        //position: relative;
        margin-bottom: 75px;
        padding: 0 20px;

        @media (max-width: 839px) {
            @include font-set(16, 19, 0.12, 600);
            margin-bottom: 45px;
        }
    }

    &__title {
        @include font-set(26, 30, 0.19, 600);
        font-size: 26px;
        letter-spacing: 0.19;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        margin-bottom: 0;

        @media (max-width: 839px) {
            @include font-set(16, 19, 0.12, 600);
        }
    }

    &__subtitle {
        @include font-set(17, 25, 0, 500);
        margin-top: 9px;
        margin-bottom: 0;
    }

    &__underline {
        display: block;
        position: relative;
        margin: 13px auto 0;
        height: 4px;
        width: 70px;

        @media (max-width: 839px) {
            margin-top: 8px;
            height: 3px;
            width: 60px;
        }

        &::before {
            content: '';
            position: absolute;
            background-color: currentColor;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }

    &__anchor {
        position: absolute;
        top: -30px;

        @media (max-width: 839px) {
            top: 30px;
        }
    }

    &--blue {
        padding: 150px 0;
        background-color: $color-blue-100;
        color: $color-dark;

        @media (max-width: 839px) {
            padding: 120px 0;
        }
    }

    &--pale {
        padding: 150px 0;
        background-color: $color-green-50;
        color: $color-dark;

        @media (max-width: 839px) {
            padding: 120px 0;
        }
    }

    &--blue {
        padding: 150px 0;
        background-color: $color-blue-100;
        color: $color-dark;

        @media (max-width: 839px) {
            padding: 120px 0;
        }

        #{$this}__head {
            color: $color-white;
        }
    }

    &.highlight-bg {
        padding: 150px;
        color: $color-dark;
        @media (max-width: 839px) {
            padding: 120px 0;
        }

        #{$this}__head {
            color: $color-white;
        }
    }

    &--white,
    &--light {
        padding: 150px 0;
        background-color: $color-white;
        color: $color-dark;

        @media (max-width: 839px) {
            padding: 120px 0;
        }

        #{$this}__head {
            color: $color-dark;
        }
    }
}

.c-section--white + .c-section--white {
    padding-top: 0;
}


.c-section + .c-section--white,
.c-section + .c-section--light,
.c-section + .highlight-bg {
    margin-top: 120px;
}

.c-section--white + .highlight-bg,
.c-section--light + .highlight-bg,
.highlight-bg + .c-section--white,
.highlight-bg + .c-section--light,
.c-section--white + .c-section--white,
.c-section--light + .c-section--light {
    margin-top: 0;
}

.c-section--pale + .c-section--pale {
    padding-top: 0;
}
