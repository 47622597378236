h1 {
    @include font-set(36, 44, 0, 900, $font-code);
}

h2 {
    @include font-set(54, 54, 0, 700);

    @media (max-width: 839px) {
        @include font-set(36, 38, 0, 700);
    }
}

h3 {
    @include font-set(26, 30, 0.19, 700);

    @media (max-width: 839px) {
        @include font-set(20, 26, 0.15, 700);
    }
}

h4 {
    @include font-set(20, 26, 0, 500);

    @media (max-width: 839px) {
        @include font-set(17, 24, 0, 500);
    }
}

p a {
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

p,
ol li {
    @include font-set(17, 25, 0, 400);

    @media (max-width: 839px) {
        @include font-set(15, 22, 0, 400);
    }

    & + & {
        margin-top: 12px;
    }
}

ol {
    list-style: decimal;
    padding-left: 20px;

    li {
        padding-left: 10px;
    }
}

p + ul,
p + ol {
    margin-top: 42px;
}

strong {
    font-weight: 700;
}

.u-white {
    color: $color-white;
}

.u-dark {
    color: $color-dark;
}

.u-blue {
    color: $color-blue-100;
}

a {
    &.u-blue {
        &:hover,
        &:focus {
            color: $color-blue-100;
        }
    }
}
