@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-cta {

    padding: 160px 0;
    min-height: 500px;

    @media (max-width: 839px) {
        padding: 120px 20px;
    }

    &__pattern {
        color: $color-white;
        width: 772px;
        position: absolute;
        opacity: 0.2;

        top: 60px;
        left: 50%;

        &.flipped {
            bottom: 60px;
            transform: rotateZ(180deg) translateX(100%);
        }


        svg {
            fill: currentColor;
        }

        @media (max-width: 839px) {
            top: 61px;
            right: 0;

            width: 256px;
        }
    }

    &__button {
        @include font-set(10, 11, 1.64, 500);
        height: 100%;
        text-transform: uppercase;
        padding: 12px 15px;
        border-radius: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        background-color: $color-blue-100;
        text-decoration: none;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;


        &:hover {

            background-color: $color-blue-800;
        }

        @media (max-width: 839px) {
            border-radius: 9999px;
            padding: 16px 23px;
            position: relative;
            z-index: 1;
            text-transform: uppercase;
            text-decoration: none;
            margin-top: 15px;
        }
    }

    p {
        max-width: 640px;
        margin: 0 auto;
    }

    a {
        margin: 30px auto 140px;
        width: 200px;
        height: 57px;
        font-size: 14px;

        @media (max-width: 839px) {
            margin: 30px auto 60px;
        }
    }

    h2 {
        font-family: $font-code;
        text-transform: uppercase;
        color: $color-blue-100;

        font-size: 120px;
        line-height: 130px;
        // letter-spacing: 0px;
        font-weight: 700;
        max-width: 1200px;
        margin: 0 auto 60px auto;

        @media (max-width: 839px) {
            font-size: 60px;
            line-height: 68px;
        }
    }

    .subtitle {
        position: relative;
        font-size: 20px;
        padding-bottom: 30px;
        margin-bottom: 30px;
        &::after {
            content: '';
            width: 90px;
            height: 3px;
            background-color: $color-red-500;
            position: absolute;
            bottom: 0;
            left: calc(50% - 45px);
        }
    }

    .content {
        font-size: 17px;
    }
}
