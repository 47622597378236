@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-navigation {
    position: sticky;
    z-index: 89;
    top: 0;
    bottom: 0;
    width: 100%;
    color: $color-white;
    background-color: $color-grey-300;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    @media (max-width: 839px) {
        position: fixed;
        z-index: 100;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto;
        transform: translate3d(100vw, 0, 0);
        //transition: transform 0.3s ease-in-out 0.1s;

        .s-nav-active & {
            transform: translate3d(0, 0, 0);
            transition: transform 0.3s ease-in-out 0.1s;
        }
    }

    // &__container {
    //     @media (max-width: 839px) {
    //         position: absolute;
    //         top: 0;
    //         bottom: 0;
    //         left: 0;
    //         right: 0;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         background-color: $color-grey-300;
    //         transform: translate3d(100vw, 0, 0);
    //         transition: transform 0.3s ease-in-out 0.1s;

    //         .s-nav-active & {
    //             transform: translate3d(0, 0, 0);
    //             transition: transform 0.3s ease-in-out 0.1s;
    //         }
    //     }
    // }

    &__list {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        list-style: none;
        width: 100%;
        margin: 0;
        padding: 0;

        @media (max-width: 839px) {
            flex-flow: column wrap;
        }
    }

    &__item {
        position: relative;
        padding: 0 30px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        @media (max-width: 839px) {
            opacity: 0;
            padding-left: 0;
            padding-right: 0;

            & + & {
                margin-top: 15px;
            }

            .s-nav-active & {
                animation: stagger-in 0.6s ease-in forwards;
                $n: 6;
                @for $x from 2 through $n {
                    &:nth-child(#{$x}) {
                    animation-delay: 150ms * ($x - 1);
                    }
                }
            }
        }

    //     @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //         /* IE10+ CSS styles go here */
    //         width: 25%;
    //         text-align: center;
    //    }
    }

    @keyframes stagger-in {
        0% {
            opactiy: 0;
            //transform: translateY(-6px);
        }
        100% {
            opacity: 1;
            //transform: translateY(0);
        }
    }

    &__link {
        @include font-set(13, 15, 2.04, 500);
        padding: 15px 0;
        color: $color-white;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block;
        transition: color 0.3s ease;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            height: 4px;
            width: 100%;
            background-color: $color-grey-300;
            transition: background-color 0.3s ease;
        }
    }

    &__button {
        @include font-set(13, 15, 2.04, 500);
        height: 100%;
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        //background-color: $color-blue-100;
        cursor: pointer;

        @media (max-width: 839px) {
            border-radius: 9999px;
            padding: 16px 23px;
            position: relative;
            z-index: 1;
            text-transform: uppercase;
            text-decoration: none;
            margin-top: 15px;
        }
    }
}

.u-sticky-only {
    display: none;
}

.s-sticky-active,
.s-nav-active {
    .u-sticky-only {
        display: inherit;
    }
}
