@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';


.c-quote {
    text-align: center;
    position: relative;
    max-width: 960px;
    margin: 0 auto;
    padding: 120px;

    @media (max-width: 839px) {
        padding: 100px 20px;
        max-width: 580px;
    }

    blockquote {
        position: relative;
        margin: 0;
        @include font-set(36, 47, 0, 900, $font-code);

        @media (max-width: 839px) {
            @include font-set(27, 33, 0, 900, $font-code);
        }

        &.show-quotes {

            &::before,
            &::after {
                @include font-set(300, 300, 0, 700);
                font-family: Georgia, 'Times New Roman', Times, serif;
                position: absolute;
                color: rgba($color-white, 0.2);

                @media (max-width: 839px) {
                    @include font-set(200, 200, 0, 700);
                }
            }

            &::before {
                content: '“';
                top: -50px;
                left: -160px;

                @media (max-width: 839px) {
                    left: 0;
                    top: -100px;
                }
            }

            &::after {
                content: '”';
                bottom: -230px;
                right: -160px;

                @media (max-width: 839px) {
                    right: 0;
                    bottom: -190px;
                }
            }
        }
    }

    cite {
        display: inline-block;
        position: relative;
        @include font-set(17, 24, 0, 600);
        margin-top: 40px;

        @media (max-width: 839px) {
            margin-top: 16px;
            max-width: 80%;
        }

        span {
            font-weight: 400;
        }

        &::before {
            content: '';
            position: absolute;
            // top: calc(50% - 1px);
            top: 10px;
            left: -18px;
            width: 12px;
            height: 2px;
            background-color: currentColor;
        }
    }

    .c-section--white &,
    .c-section--light & {
        blockquote {

            &::before,
            &::after {
                color: rgba($color-border, 0.1);
            }
        }
    }

    &--toupper blockquote {
        text-transform: uppercase;
    }

}