@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-people {
    $this: &;

    position: relative;
    max-width: 1440px;
    padding: 100px 24px 0;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    @media (max-width: 839px) {
        flex-flow: column wrap;
        padding: 0 20px;
    }

    // &__pattern {
    //     color: $color-border;
    //     width: 100%;
    //     max-width: 1202px;
    //     position: absolute;
    //     top: 33px;
    //     opacity: 0.1;

    //     svg {
    //         fill: currentColor;
    //     }

    //     @media (max-width: 839px) {
    //         display: none;
    //     }
    // }

    & + & {
        // #{$this}__pattern {
        //     display: none;
        // }
    }
}

.c-person {
    width: 32%;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 90px;
    padding-left: 12px;
    padding-right: 12px;

    @media (max-width: 839px) {
        width: 100%;
        flex-flow: row;
        margin-bottom: 48px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba($color-border, 0.1);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &:first-child {
        margin-left: 0;
    }

    &__image {
        position: relative;
        z-index: 2;
        width: 282px;
        height: 282px;
        border: 6px solid $color-green-50;
        border-radius: 100%;
        overflow: hidden;
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-bottom: 60px;

        & > .c-image > .c-image__image {
            object-fit: none;
        }

        @media (max-width: 839px) {
            width: 99px;
            height: 99px;
            left: 3px;
            flex-shrink: 0;
            margin-bottom: 0;
            margin-right: 24px;
            border: 3px solid $color-green-50;

            & > .c-image > .c-image__image {
                object-fit: cover;
            }
        }


    }

    &__border {
        position: absolute;
        width: 294px;
        height: 294px;
        background-color: $color-dark;
        border-radius: 100%;
        top: -6px;
        left: 50%;
        margin-left: -147px;
        z-index: 1;

        @media (max-width: 839px) {
            width: 105px;
            height: 105px;
            flex-shrink: 0;
            top: -3px;
            left: 0;
            margin-left: 0;
        }
    }

    &__details {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 839px) {
            align-items: flex-start;
            text-align: left;
        }
    }

    &__title {
        @include font-set(14, 16, 2.2, 400);
        text-transform: uppercase;
        margin: 0;

        @media (max-width: 839px) {
            @include font-set(12, 12, 1.89, 400);

        }
    }

    &__name {
        @include font-set(26, 30, 0.19, 700);
        text-transform: uppercase;
        margin: 0;

        @media (max-width: 839px) {
            @include font-set(21, 21, 0.15, 700);
            text-align: left;
        }
    }

    &__sport {
        @include font-set(20, 26, 0, 500);
        margin: 0;

        @media (max-width: 839px) {
            @include font-set(17, 24, 0, 500);
        }
    }

    &__extra {
        margin-top: 9px;

        & > * {
            @include font-set(16, 22, 0, 400);
        }

        & > :first-child {
            margin-top:0;
        }

        @media (max-width: 839px) {
            text-align: left;
        }
    }
}

.c-person__title + .c-person__name {
    margin-top: 10px;
}

.c-person__name + .c-person__sport {
    margin-top: 8px;
}
