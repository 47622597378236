@import '../../scss/settings/vars';

.c-richtext {
    position: relative;
    max-width: 960px;
    padding: 0 60px;
    margin: 0 auto 75px;

    & > :first-child {
        margin-top: 0;
    }

    @media (max-width: 839px) {
        text-align: left;
        padding: 0 20px;
        margin-bottom: 45px;
    }

    a {
        color: $color-blue-800;
        transition: color 0.3s ease;

        &:hover,
        &:focus {
            color: $color-blue-100;
            text-decoration: none;
        }
    }
}
