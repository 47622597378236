@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-stats {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 0 20px;

    @media (max-width: 839px) {
        flex-flow: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }

    &__stat {
        width: 100%;
        max-width: 282px;
        padding: 30px;
        //border: 1px solid rgba($color-border, 0.1);
        margin:0 37.5px 90px;

        @media (max-width: 839px) {
            margin-bottom: 30px;
            width:100%;
            max-width: 100%;
            border: none;
            border-bottom: 1px solid rgba($color-border, 0.1);

            &:last-child {
                border: none;
            }
        }
    }

    &__stat-number {
        @include font-set(99, 99, 0, 700);

        @media (max-width: 839px) {
            @include font-set(69, 69, 0, 700);
        }
    }

    h3 {
        margin: 0;

        @media (max-width: 839px) {
            @include font-set(21, 21, 0.15, 700);
        }
    }

    img {
        margin-top: 20px;
    }
}
