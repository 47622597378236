@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-mandate {
    $this: &;

    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    overflow-x: hidden;


    &__image {
        width: 100%;
        //height: 504px;
        height: auto;
        flex-shrink: 0;
        margin: 0 auto;

        @media (max-width: 839px) {
            max-width: calc(100% - 50px);
        }
        @media (max-width: 479px) {
            max-width: calc(100% - 30px);
        }
    }

    & + & {
        margin-top: 90px;
    }
}
