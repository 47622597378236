@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-footer {
    background-color: $color-blue-100;
    background-size: cover;
    background-position: -10% -3%;

    @media (max-width: 839px) {
        background-size: cover;
        background-position: center center;
    }

    &__inner {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        justify-content: flex-start;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 63px 15px 41px;

        @media (max-width: 839px) {
            flex-flow: column wrap;
        }
    }

    &__column {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        justify-content: flex-start;
        width: 50%;
        margin-bottom: 60px;

        @media (max-width: 839px) {
            flex-flow: column wrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 30px;
        }

        &:nth-child(2) {
            flex-flow: column wrap;
            justify-content: flex-end;
            margin-left: auto;

            @media (max-width: 839px) {
                margin-bottom: 60px;
            }
        }
    }

    &__logo {
        width: 120px;
        height: 120px;

        @media (max-width: 839px) {
            margin-bottom: 30px;
        }
    }

    &__text {
        margin-left: 60px;

        @media (max-width: 839px) {
            margin-left: 0;
            text-align: center;
        }

        p {
            margin: 0;
        }

        & > :last-child {
            padding: 0;
        }
    }

    &__follow {
        @media (max-width: 839px) {
            text-align: center;
        }
    }

    &__social-list {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;

        li {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__icon {
    }

    &__utility {
        display: flex;
        width: 100%;
        border-top: 1px solid $color-white;
        padding-top: 10px;

        @media (max-width: 839px) {
            flex-flow: column wrap;
            align-items: center;
            justify-content: center;
            padding-top: 15px;
        }

        p {
            @include font-set(13, 25, 0, 400);
            margin: 0;
            padding: 0;
        }
    }

    &__navigation {
        margin-left: auto;

        @media (max-width: 839px) {
            margin-left: 0;
        }

        ul {
            list-style: none;
            display: flex;
            align-items: flex-start;
            margin: 0;
            padding: 0;
        }
    }

    &__link {
        @include font-set(13, 25, 0, 400);
        padding: 0 12px;
        color: $color-white;
        text-decoration: none;
        cursor: pointer;

        @media (max-width: 839px) {
            padding: 0 12px 0 0;
        }

    }

}
