@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-biglist {
    @media (max-width: 839px) {
        width: 100%;
        padding: 0 20px;
    }
}

.c-bigitem {
    padding-bottom: 30px;
    max-width: 792px;
    margin: 0 auto;

    @media (max-width: 839px) {
        max-width: 335px;
    }

    &__title {
        @include font-set(26, 26, 0.19, 700, $font-graphik);
        color: $color-blue-100;
        margin-bottom: 12px;
    }

    &__subtitle {
        @include font-set(20, 26, 0, 500, $font-graphik);
        margin-bottom: 12px;
    }

    &__description {
        @include font-set(14, 16, 2.2, 400, $font-graphik);
        text-transform: uppercase;
        margin: 0;
    }

    &__extratitle {
        @include font-set(18, 22, 0, 700, $font-graphik);
        text-transform: uppercase;
        margin-top: 48px;
    }

    & + & {
        border-top: 1px solid rgba($color-border, 0.1);
        padding-top: 30px;
    }
}
