// Colors
//----------------------------------

$color-white: #FFFFFF;
$color-grey-50: #FEFEFE;
$color-grey-100:#D8D8D8;
$color-grey-300:#363737;

$color-dark: #000000;
$color-border: #040505;

// Bronze
$color-bronze-100: #CB9748;

// Silver
$color-silver-100: #C0C0C0;

// Gold
$color-gold-100: #FFD700;

// Themes / Green
$color-green-50:#EAF3E8;
$color-green-100: #44A751;
$highlight-green: $color-green-100;

// Themes / Blue
$color-blue-100: #059ABA;
$color-blue-800: #037EA3;
$highlight-blue: $color-blue-800;

// Themes / Red
$color-red-500: #CF1F3B;
$color-red-50: #F9E4E7;
$highlight-red: $color-red-500;

// Themes / Gold
$color-gold-500: #B3964B;
$color-gold-50: #fbfaf6;
$color-gold-100: #f4efe4;
$color-gold-200: #e5dac0;
$color-gold-300: #d6c69c;
$color-gold-400: #c7b178;
$highlight-gold: $color-gold-500;

// Type
//----------------------------------

$font-graphik: 'Graphik', Arial, Helvetica, sans-serif;
$font-code: 'Code Pro', Arial, Helvetica, sans-serif;
