@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-arrows {
    $this: &;

    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    //margin-bottom: 120px;
    margin-bottom: 30px;
    padding: 0 20px;

    @media (max-width: 839px) {
        flex-flow: column wrap;
        //margin-bottom: 90px;
    }
}

.c-arrow {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $color-blue-100;
    color: $color-white;
    text-transform: uppercase;
    text-align: left;
    width: calc(50% - (64px / 2));
    padding: 30px;
    min-height: 150px;
    position: relative;
    margin-right: 20px;
    margin-bottom: 30px;

    @media (max-width: 839px) {
        width: calc(100% - 20px);
        min-height: 144px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -20px;
        height: 100%;
        width: 20px;
        background-color: inherit;
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
    }

    &__text {
        //width: 80%;
        margin: 0;

        > div > * {
            @include font-set(26, 30, 0.19, 700);

            @media (max-width: 839px) {
                @include font-set(20, 26, 0.15, 700);
            }
            > * {
                font-style: normal;
                color: #000000;
            }
        }

        &--big > div > * {
            @include font-set(40, 44, 0, 700);
            margin: 0;

            @media (max-width: 839px) {
                @include font-set(36, 38, 0, 700);
            }

        }

    }

    &--white {
        background-color: $color-white;
        // TODO: Make color CMS-able
        color: #000;

        &::after {
            border-color: transparent transparent transparent $color-white;
        }
    }

    &--big {
        @include font-set(54, 54, 0, 900, $font-code);
        width: 100%;
        margin-left: 0 !important;

        @media (max-width: 839px) {
            width: calc(100% - 20px);
            min-height: 174px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &--small {
        font-family: $font-code;
        font-weight: 900;

        @media (max-width: 839px) {
            min-height: 144px;

            &:nth-child(even) {
                margin-left: 0;
            }

            &:nth-child(n + 3) {
                margin-top: inherit;
            }

            &:nth-child(n + 2) {
                margin-top: 30px;
            }
        }
    }

    // & + & {
    //     margin-top: 30px;
    // }
}
