@import '../../scss/settings/vars';
@import '../../scss/tools/mixins';

.c-download {
    // background-color: rgba($color-green-50, 0.13);
    border-radius: 3px;
    // width: 100%;
    // max-width: 531px;
    margin: 120px auto 0;
    padding: 30px 50px 26px;
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 839px) {
        flex-flow: column wrap;
    }

    &__link {
        @include font-set(10, 11, 1.64, 500);
        display: flex;
        align-items: center;
        color: $color-white;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;

        .c-section--white &,
        .c-section--light & {
            color: $color-dark;

            .c-download__icon svg path {
                fill: $color-dark;
            }
        }


        &:hover {
            .c-download__icon {
                transform: translateY(-4px);
            }
        }
    }

    &__button {
        @include font-set(10, 11, 1.64, 500);
        height: 100%;
        text-transform: uppercase;
        padding: 12px 15px;
        border-radius: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        background-color: $color-blue-100;
        text-decoration: none;
        cursor: pointer;
        transition: transform 0.3s ease-in-out 0.1s;

        &:hover {
            transform: scale(1.05);
        }

        @media (max-width: 839px) {
            border-radius: 9999px;
            padding: 16px 23px;
            position: relative;
            z-index: 1;
            text-transform: uppercase;
            text-decoration: none;
            margin-top: 15px;
        }
    }

    &__icon {
        display: inline-block;
        position: relative;
        top: -1px;
        height: 10px;
        width: 8px;
        margin-left: 7px;
        transition: transform 0.3s ease-in-out 0.1s;
    }

}

.c-download__link + .c-download__button {
    margin-left: 40px;

    @media (max-width: 839px) {
        margin-left: 0;
    }
}
